/* nunito-200normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Nunito Extra Light '),
    local('Nunito-Extra Light'),
    url(/static/media/nunito-latin-200.6716dd95.woff2) format('woff2'), 
    url(/static/media/nunito-latin-200.4b3dd3e0.woff) format('woff'); /* Modern Browsers */
}

/* nunito-200italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Nunito Extra Light italic'),
    local('Nunito-Extra Lightitalic'),
    url(/static/media/nunito-latin-200italic.06dea2b4.woff2) format('woff2'), 
    url(/static/media/nunito-latin-200italic.429b4b7f.woff) format('woff'); /* Modern Browsers */
}

/* nunito-300normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Nunito Light '),
    local('Nunito-Light'),
    url(/static/media/nunito-latin-300.f708870c.woff2) format('woff2'), 
    url(/static/media/nunito-latin-300.3eb1a100.woff) format('woff'); /* Modern Browsers */
}

/* nunito-300italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Nunito Light italic'),
    local('Nunito-Lightitalic'),
    url(/static/media/nunito-latin-300italic.6d3fe135.woff2) format('woff2'), 
    url(/static/media/nunito-latin-300italic.2c148521.woff) format('woff'); /* Modern Browsers */
}

/* nunito-400normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Nunito Regular '),
    local('Nunito-Regular'),
    url(/static/media/nunito-latin-400.fe67e104.woff2) format('woff2'), 
    url(/static/media/nunito-latin-400.feb87cc9.woff) format('woff'); /* Modern Browsers */
}

/* nunito-400italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Nunito Regular italic'),
    local('Nunito-Regularitalic'),
    url(/static/media/nunito-latin-400italic.f631a08d.woff2) format('woff2'), 
    url(/static/media/nunito-latin-400italic.aafaf212.woff) format('woff'); /* Modern Browsers */
}

/* nunito-600normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Nunito SemiBold '),
    local('Nunito-SemiBold'),
    url(/static/media/nunito-latin-600.e57dde6f.woff2) format('woff2'), 
    url(/static/media/nunito-latin-600.ae6d08e9.woff) format('woff'); /* Modern Browsers */
}

/* nunito-600italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Nunito SemiBold italic'),
    local('Nunito-SemiBolditalic'),
    url(/static/media/nunito-latin-600italic.0fdd3929.woff2) format('woff2'), 
    url(/static/media/nunito-latin-600italic.7ddb3a64.woff) format('woff'); /* Modern Browsers */
}

/* nunito-700normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Nunito Bold '),
    local('Nunito-Bold'),
    url(/static/media/nunito-latin-700.dd42f32a.woff2) format('woff2'), 
    url(/static/media/nunito-latin-700.08673382.woff) format('woff'); /* Modern Browsers */
}

/* nunito-700italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Nunito Bold italic'),
    local('Nunito-Bolditalic'),
    url(/static/media/nunito-latin-700italic.3a0274f5.woff2) format('woff2'), 
    url(/static/media/nunito-latin-700italic.812a8ec8.woff) format('woff'); /* Modern Browsers */
}

/* nunito-800normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Nunito ExtraBold '),
    local('Nunito-ExtraBold'),
    url(/static/media/nunito-latin-800.389848e3.woff2) format('woff2'), 
    url(/static/media/nunito-latin-800.e345e7e8.woff) format('woff'); /* Modern Browsers */
}

/* nunito-800italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Nunito ExtraBold italic'),
    local('Nunito-ExtraBolditalic'),
    url(/static/media/nunito-latin-800italic.26ad2c5e.woff2) format('woff2'), 
    url(/static/media/nunito-latin-800italic.f5ebcebb.woff) format('woff'); /* Modern Browsers */
}

/* nunito-900normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Nunito Black '),
    local('Nunito-Black'),
    url(/static/media/nunito-latin-900.6d3158a3.woff2) format('woff2'), 
    url(/static/media/nunito-latin-900.b1b0d941.woff) format('woff'); /* Modern Browsers */
}

/* nunito-900italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Nunito Black italic'),
    local('Nunito-Blackitalic'),
    url(/static/media/nunito-latin-900italic.f3daf4bf.woff2) format('woff2'), 
    url(/static/media/nunito-latin-900italic.2ebb1fcb.woff) format('woff'); /* Modern Browsers */
}


